@import '_reset.scss';
@import '_definitions.scss';

body {
    font-family: 'Quicksand', sans-serif;
    opacity: 0;
    transition: opacity .4s ease-in;
    letter-spacing: 0.02em;

    &.loaded {
        opacity: 1;
    }
}

.bx-wrapper {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
    bottom: 40px;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background-color: white;
    z-index: 1;
    position: relative;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
    background-color: $pink;
}

.pink {
    color: $pink;
}

@import '_globals.scss';
@import '_navigation.scss';
@import '_start.scss';
@import '_start-navigation.scss';
@import '_page-top.scss';
@import '_slider.scss';
@import '_slider-controls.scss';
@import '_page-content.scss';
@import '_ask-yourself.scss';
@import '_advice.scss';
@import '_footer.scss';
